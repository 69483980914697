<template>
  <v-sheet>
    <Slider
      :token="g_token"
      :data="g_school.slider_photo"
      class="text-center"
    />
    <v-card id="viewAllInformation" class="elevation-0 pa-3 margin-top mx-10">
      <v-row no-gutters align="center">
        <div>
          <span class="font-weight-bold display-1">
            {{
              route === "news"
                ? $t("homepage.news")
                : $t("homepage.announcement")
            }}
          </span>
          <div class="font-weight-bold subtitle">
            {{
              route === "news"
                ? $t("homepage.news_sub")
                : $t("homepage.announcement_sub")
            }}
          </div>
        </div>
        <v-spacer />

        <v-menu v-model="menu" :close-on-content-click="false" offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" outlined v-bind="attrs" v-on="on">
              Filter
              <v-icon>mdi-filter-outline</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item
                :class="activeFilter == 'latest' && 'gradient-primary'"
                @click="setFilter('latest')"
              >
                <v-list-item-action
                  :class="activeFilter == 'latest' && 'white--text'"
                  >{{ $t("app.latest") }}</v-list-item-action
                >
              </v-list-item>
              <v-list-item
                :class="activeFilter == 'oldest' && 'gradient-primary'"
                @click="setFilter('oldest')"
              >
                <v-list-item-action
                  :class="activeFilter == 'oldest' && 'white--text'"
                  >{{ $t("app.oldest") }}</v-list-item-action
                >
              </v-list-item>
              <v-list-item
                :class="activeFilter == 'most_viewed' && 'gradient-primary'"
                @click="setFilter('most_viewed')"
              >
                <v-list-item-action
                  :class="activeFilter == 'most_viewed' && 'white--text'"
                  >{{ $t("app.most_viewed") }}</v-list-item-action
                >
              </v-list-item>
              <v-list-item
                :class="activeFilter == 'most_liked' && 'gradient-primary'"
                @click="setFilter('most_liked')"
              >
                <v-list-item-action
                  :class="activeFilter == 'most_liked' && 'white--text'"
                  >{{ $t("app.most_liked") }}</v-list-item-action
                >
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-row>
    </v-card>

    <v-card flat class="elevation-0 pa-3 mx-10">
      <v-row justify="start" align="center" class="my-12">
        <v-col cols="12" v-if="data.length == 0">
          <v-img
            src="@/assets/svg/information_no_data.svg"
            max-width="400px"
            class="mx-auto"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-for="(a, i) in data"
          :key="i"
          class="mt-4"
        >
          <v-card
            :class="darkmode ? 'black' : 'white'"
            class="text-left"
            flat
            elevation="1"
            height="100%"
          >
            <v-img
              v-if="a.file_type == 'youtube' || a.file_type == 'image'"
              :src="a.thumbnail"
              height="180"
            ></v-img>
            <div v-else class="pa-4">
              <v-img :src="a.thumbnail" height="150" contain></v-img>
            </div>
            <v-card-title class="subtitle-1 mt-n1">
              <a
                color="primary"
                class="card-title-wrap text-truncate"
                text
                @click="
                  $router.push({
                    name: 'InformationDetail',
                    params: { type: 'announcement' },
                    query: {
                      id: a.id,
                      title: join(a.title)
                    }
                  })
                "
                >{{ a.title }}
              </a>
            </v-card-title>
            <v-card-text>
              <div class="caption card-caption-wrap">
                <span v-html="truncateHtml(a.description)" />
                <span v-if="a.description == isFalse">{{
                  $t("homepage.no_description")
                }}</span>
              </div>
              <span class="font-weight-light" style="font-size: 11px">
                {{ $t("app.posted_on") }} {{ a.created_date | dateFormat }}
              </span>
            </v-card-text>
            <v-card-actions class="mx-2" style="font-size: 11px">
              <a class="mr-2">
                <v-icon
                  :color="a.status_likes ? 'primary' : ''"
                  :disabled="!g_token"
                  small
                  @click="likeHandler(a.id)"
                  >mdi-thumb-up-outline</v-icon
                >
                <span
                  :style="darkmode ? 'color: white' : 'color: black'"
                  class="ml-1 grey--text"
                  >{{ a.likes }}</span
                >
              </a>
              <a class="mr-2">
                <v-icon small>mdi-eye-outline</v-icon>
                <span
                  :style="darkmode ? 'color: white' : 'color: black'"
                  class="ml-1 grey--text"
                  >{{ a.view }}</span
                >
              </a>
              <v-spacer />
              <SocialSharing small :data="setSharing(a)" />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        v-if="data.length != total"
        justify="center"
        class="mt-4"
        color="primary"
      >
        <v-btn color="primary" text @click="viewMore">{{
          $t("homepage.view_more")
        }}</v-btn>
      </v-row>
    </v-card>
  </v-sheet>
</template>

<script>
import {
  getInformation,
  setLikes,
  getInformationEmployee
} from "@/api/admin/information";
import moment from "moment/moment";
moment.locale("id");
import { mapGetters } from "vuex";
import truncate from "truncate-html";

let typingTimer;
const doneTypingInterval = 750;

export default {
  components: {
    SocialSharing: () => import("@/components/SocialSharing"),
    Slider: () => import("./components/Slider")
  },
  created() {
    this.route = this.$route.params.type;
    if (this.route === "announcement" || this.route === "news") {
      this.queryData.type = this.route;
      this.getListData();
    }

    setTimeout(() => {
      const getElement = document.getElementById("viewAllInformation");
      this.$vuetify.goTo(getElement);
    }, 200);
  },
  filters: {
    dateFormat: value => {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY");
    }
  },
  computed: {
    ...mapGetters(["g_token", "g_school"]),
    darkmode() {
      return this.$store.getters.g_darkmode;
    }
  },
  data() {
    return {
      route: "",
      total: 0,
      menu: false,
      data: [],
      activeFilter: "",
      isFalse: "",
      queryData: {
        limit: 8,
        type: "",
        search: "",
        sort: "",
        order: "DESC"
      },
      thumbnail: {
        pdf: require("@/assets/svg/thumbnail_pdf.svg"),
        video: require("@/assets/svg/thumbnail_video.svg"),
        nofilenews: require("@/assets/svg/thumbnail_nofile_news.svg"),
        nofileannouncement: require("@/assets/svg/thumbnail_nofile_announcement.svg")
      }
    };
  },
  methods: {
    setSharing(data) {
      if (data) {
        const setUrl = `${window.location.origin}/information/${
          data.type
        }/detail?id=${data.id}&title=${data.title.split(" ").join("-")}`;
        return {
          url: setUrl,
          title: data.title,
          description: data.description
        };
      }
    },
    searchHandler() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.queryData.page = 1;
        this.getListData();
      }, doneTypingInterval);
    },
    splitUrl(url, onlyCode) {
      if (url) {
        const splitUrl = url.split("watch?v=");
        if (
          splitUrl[0] == "https://www.youtube.com/" ||
          splitUrl[0] == "www.youtube.com/"
        ) {
          let idYoutube = url.split("v=")[1];
          var ampersAndPosition = idYoutube.indexOf("&");
          if (ampersAndPosition != -1) {
            idYoutube = idYoutube.substring(0, ampersAndPosition);
          }
          if (onlyCode && idYoutube) {
            return idYoutube;
          } else if (onlyCode && !idYoutube) {
            return "";
          } else {
            return "https://www.youtube.com/embed/" + idYoutube;
          }
        }
      }
    },
    join(title) {
      return title.split(" ").join("-");
    },
    truncateHtml: html => truncate(html, 4, { byWords: true, stripTags: true }),
    viewMore() {
      this.queryData.limit = this.queryData.limit + this.queryData.limit;
      this.getListData();
    },
    likeHandler(id) {
      if (this.g_token) {
        this.$Progress.start();
        setLikes({ id: id }).then(res => {
          if (res.data.code) {
            this.getListData();
          }
          this.$Progress.finish();
        });
      }
    },
    async getListData() {
      let response = [];
      this.$Progress.start();
      try {
        if (this.g_token) {
          response = await getInformationEmployee(this.queryData);
        } else {
          response = await getInformation(this.queryData);
        }
        if (response.data.code) {
          const tempData = [];
          const data = response.data.data.data;

          data.map(r => {
            let thumbnail = "";
            if (r.file_type) {
              switch (r.file_type) {
                case "youtube": {
                  const code = this.splitUrl(r.thumbnail, "onlyCode");
                  if (code)
                    thumbnail = `https://img.youtube.com/vi/${code}/0.jpg`;
                  break;
                }
                case "pdf":
                  thumbnail = this.thumbnail.pdf;
                  break;
                case "video":
                  thumbnail = this.thumbnail.video;
                  break;
                default:
                  thumbnail = r.thumbnail;
                  break;
              }
            } else {
              if (this.route == "news") {
                thumbnail = this.thumbnail.nofilenews;
              } else {
                thumbnail = this.thumbnail.nofileannouncement;
              }
            }
            tempData.push({ ...r, thumbnail: thumbnail });
          });

          this.data = tempData;
          this.total = response.data.data.total;
        } else {
          this.$store.commit("CALL_SNACKBAR", {
            msg: response.data.message,
            color: "error"
          });
        }
        this.$Progress.finish();
      } catch (error) {
        this.$store.commit("CALL_SNACKBAR", {
          msg: error,
          color: "error"
        });
        this.$Progress.finish();
      }
    },
    setFilter(param) {
      this.activeFilter = param;
      this.queryData.order = "DESC";
      switch (param) {
        case "latest":
          this.queryData.sort = "created_at";
          break;
        case "oldest":
          this.queryData.sort = "created_at";
          this.queryData.order = "ASC";
          break;
        case "most_viewed":
          this.queryData.sort = "view";
          break;
        default:
          this.queryData.sort = "likes";
          break;
      }
      this.getListData();
    }
  }
};
</script>
<style lang="scss" scoped>
.margin-top {
  margin-top: 100px;
}
</style>
